import React, { useState } from "react";
import RacerProfile from "../../components/racerProfile";
// import img05 from '../../asset/img/박석찬_MMX(테블릿)2 4.png';
// import img09 from '../../asset/img/이동환_MMX(테블릿) 4.png';
// import img09 from '../../asset/img/박서인_MMX.jpg';
// import img00 from "../../asset/img/김동우_MMX(테블릿) 3.png";
import img01 from "../../asset/img/MMX_강진성.jpeg";
import img02 from "../../asset/img/MMX_박서인.jpeg";
import img03 from "../../asset/img/MMX_김동우.jpeg";

const HomeDetail2 = ({ teamRef }) => {
  const racerList = [
    {
      name: "강진성",
      enNum: "(Entry NO. 44)",
      content1:
        "안녕하세요. 슈퍼레이스 GT1클래스에 참가하는 MMX MOTORSPORTS 강진성 입니다.",
      // content2: "레이싱을 처음 입문한건 2007년 F1800 클래스로 데뷔했지만 풀 시즌을 제대로 치룬건 2019년 GT1클래스부터 시작하게 되었습니다.",
      img: img01,
      goal: [
        "2022 CJ슈퍼레이스 GT1 3ROUNG  5위",
        "2022 CJ슈퍼레이스 GT1 2ROUNG  5위",
        "2020 CJ슈퍼레이스 GT1 4ROUNG  2위",
        "2019 CJ슈퍼레이스 GT1 5ROUNG  2위",
        "2018 CJ슈퍼레이스 GT1 7ROUNG  2위",
        "2018 CJ슈퍼레이스 GT1 5ROUNG  3위",
        "2018 CJ슈퍼레이스 GT1 4ROUNG  3위",
        "2018 CJ슈퍼레이스 GT1 2ROUNG  4위",
        "2018 CJ슈퍼레이스 GT1 1ROUNG  3위",
        "2017 CJ슈퍼레이스 6000Class 7ROUNG  9위",
        "2017 CJ슈퍼레이스 6000Class 5ROUNG  9위",
        "2013 KKC 로탁스 맥스클래스 2ROUNG  3위",
      ],
    },
    {
      name: "박서인",
      enNum: "(Entry NO. 27)",
      content1:
        "안녕하세요. 슈퍼레이스 GT1클래스에 참가하는 MMX MOTORSPORTS 박서인 입니다",
      img: img02,
      goal: [
        "2022 CJ슈퍼레이스 GT1 7ROUNG  20위",
        "2022 CJ슈퍼레이스 GT1 6ROUNG  23위",
        "2022 전남GT GT300 6위",
        "2022 CJ슈퍼레이스 5ROUNG GT1 18위",
        "2021 문체부장관배 KKC 시니어클래스 2ROUND 9위",
        "2020 문체부장관배 KKC 루키클래스 시즌 종합 2위",
        "2020 문체부장관배 KKC 루키클래스 5ROUND 3위",
        "2020 문체부장관배 KKC 루키클래스 4ROUND 5위",
        "2020 문체부장관배 KKC 루키클래스 3ROUND 2위",
        "2020 문체부장관배 KKC 루키클래스 2ROUND 2위",
        "2020 문체부장관배 KKC 루키클래스 1ROUND 4위",
        "2019 KIC CUP 로탁스맥스 첼린지 시즌 종합 3위",
      ],
    },
    {
      name: "김동우",
      enNum: "감독",
      content1: "안녕하세요. MMX MOTORSPORTS 감독 김동우 입니다.",
      img: img03,
      goal: [
        "레이싱팀 경력 12년\n",
        "일반정비업 경력  2년\n",
        "수입차 전문 정비업 경력 1년 8개월\n",
        "개인정비샵 운영 3년\n",
        ". ",
        "10 KDRC 코리아 드래그 챔피언쉽 PRO TURBO 클래스\n",
        "5전,6전 2회출전  2회 우승\n",
        ". ",
        "총괄적인  레이싱카 셋팅 및 레이싱 팀 경기 운영",
      ],
    },
  ];

  const [selectedRacer, setSelectedRacer] = useState(racerList[0]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const indexHandler = () => {
    let newIndex;
    if (selectedIndex < 2) {
      newIndex = selectedIndex + 1;
    } else {
      newIndex = 0;
    }
    setSelectedIndex(newIndex);
    setSelectedRacer(racerList[newIndex]);
  };

  return (
    <section ref={teamRef} className="xl:z-0">
      <RacerProfile
        name={selectedRacer.name}
        indexHandler={indexHandler}
        enNum={selectedRacer.enNum}
        index={selectedIndex}
        img={selectedRacer.img}
        content1={selectedRacer.content1}
        content2={selectedRacer.content2}
        goal={selectedRacer.goal}
      />
    </section>
  );
};

export default HomeDetail2;
