import './App.css';
import { BrowserRouter , Route , Routes } from "react-router-dom";
import Home from "./pages/home/home";
import StartPage from "./pages/startPage";
import {useEffect, useState} from "react";
import ImgDetailPage from "./pages/imgDetailPage";

function App () {
    const [isStarted,setIsStarted] = useState(false);

    const changeStartStatus = () => {
        setTimeout(()=>{setIsStarted(true)},3000)
    }

    useEffect(
        changeStartStatus
        ,[])

    return (
        <BrowserRouter>
            {/*<ScrollToTop>*/}
                <Routes>
                    <Route path='/' element={isStarted? <Home/>: <StartPage/>}/>
                    <Route path='/home' element={isStarted? <Home/>: <StartPage/>}/>
                    <Route path='/img/:imgNum' element={<ImgDetailPage />}/>
                </Routes>
            {/*</ScrollToTop>*/}
        </BrowserRouter>
    );
}

export default App;
