import React from "react";

const RacerProfile = ({
  name,
  enNum,
  content1,
  content2,
  img,
  index,
  indexHandler,
  goal,
}) => {
  const nextBtnHandler = () => {
    indexHandler();
  };
  return (
    <div className="p-[20px] w-full md:flex md:justify-center xl:h-[640px]">
      <div className="md:flex md:bg-[#323232] md:pt-[40px] md:pl-[40px] xl:pt-[80px] xl:pl-[60px] xl:h-[640px]">
        <div className="font-[600] mb-[20px] md:mr-5 xl:w-[530px]">
          <h2 className="text-[28px] leading-7 md:mb-[10px] xl:text-[48px] xl:mb-[17px]">
            {name}
          </h2>
          <h3 className="text-[18px] mb-2 xl:text-[28px] xl:mb-[20px]">{`${enNum}`}</h3>
          <div className="flex my-center text-[16px] font-bold mb-[10px] xl:justify-start">
            <div className="mr-[12px] xl:text-[18px]">{index + 1}/3</div>
            <div className=" w-full border-b-[1px] xl:w-[230px]"> </div>
          </div>
        </div>
        <div className="flex flex-col my-center mb-10 md:mb-0 md:mt-12 xl:absolute xl:translate-x-80 xl:translate-y-16 xl:z-20">
          <div className="text-[14px] my-center flex-col w-full xl:text-base xl:w-72 ">
            <div className="w-full h-auto md:w-[228px] xl:w-auto xl:h-[250px] xl:leading-[26px]">
              <p className="mb-5">{content1}</p>
              <p className="mb-5">{content2}</p>
            </div>
            <div
              onClick={nextBtnHandler}
              className="w-full font-bold cursor-pointer"
            >
              NEXT <span className="xl:text-[14px]"> {`>>`} </span>
            </div>
          </div>
        </div>
      </div>
      <div className="my-center relative xl:w-[750px] xl:h-[640px] hover:bg-[#00ff0000]">
        <div className="w-full bg-[#1F1F1F] xl:my-center xl:h-[640px]">
          <img
            className="w-full h-auto xl:w-auto xl:h-full object-cover"
            src={img}
            alt=""
          />
        </div>
        <div className="absolute bg-[#00ff0000] hover:z-30 text-[#00ff0000] hover:text-white hover:bg-[#1F1F1F80] top-0 py-10 px-4 w-full h-full xl:h-[640px] xl:p-20 ">
          <div className="font-bold md:text-[24px] xl:text-[28px] xl:mb-[20px]">
            수상경력
          </div>
          <ul className="pb-10 pt-5 text-[14px] leading-8 md:text-[14px] xl:text-[20px]">
            {goal.map((x) => (
              <li className="leading-normal">{x}</li>
            ))}
          </ul>
        </div>
        <div className="absolute bottom-20 md:bottom-24 translate-x-32 md:translate-x-36 flex my-center -rotate-90 xl:-translate-x-[850px]">
          <div className="border-b-2 w-20 md:w-32 mr-5 xl:w-16"> </div>
          <div className="font-bold text-lg">금호 GT1</div>
        </div>
      </div>
    </div>
  );
};

export default RacerProfile;
