import React , { useState } from 'react';
import menuIcon from '../asset/icons/headerIcon.png';
import headerLogo from '../asset/logos/headerLogo.png';
import headerLogoSVG from '../asset/logos/mmxLogoSVG.svg';

const Header = ( {home1Scroll, teamScroll, galleryScroll, videoScroll, contactScroll, scheduleScroll } ) => {
    const [headerClicked, setHeaderClicked] =useState(false);
    
    const menuClick = () => {
        headerClicked? setHeaderClicked(false):setHeaderClicked(true);
    }
    
    return(
        !headerClicked?
            <header className="w-full fixed h-[80px] top-0 flex items-center justify-between z-50">
                <div className="m-5 cursor-pointer" onClick={menuClick}>
                    <img src={menuIcon} alt=""/>
                </div>
                <div className="absolute content-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div>
                        <img src={headerLogoSVG} alt=""/>
                    </div>
                </div>
            </header>
            :
            <div className="bg-black w-screen h-screen z-50">
                <header className="w-full fixed h-[80px] top-0 flex items-center justify-between z-50 bg-black">
                    <div className="m-5 text-3xl cursor-pointer " onClick={menuClick}>
                        ❌
                    </div>
                    <div className="absolute content-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black">
                        <div>
                            <img src={headerLogo} alt=""/>
                        </div>
                    </div>
                </header>
                <div className="fixed w-full bg-black h-screen flex items-center text-4xl font-bold tracking-tighter bg-black bg-black z-40 md:justify-center">
                    <ul className="md:flex md:text-2xl xl:text-4xl">
                        <li onClick={menuClick} className="p-3 cursor-pointer">
                            <div onClick={home1Scroll} className="fill-white hover:fill-red-500">
                                <svg width="148" height="54" viewBox="0 0 148 54" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 48.8901L3.56324 4.69678H16.0754L19.5774 48.8901H12.5989L12.0731 41.7585H7.62675L7.16731 48.8901H0ZM8.15256 34.7035H11.5065L9.89334 12.2316H9.55641L8.15256 34.7035Z"/>
                                    <path d="M34.2643 49.2934C33.034 49.2934 32.0335 48.9309 31.2677 48.2009C30.4969 47.4709 29.8843 46.3631 29.4248 44.8725V48.8901H21.7981V4.69678H29.4248V15.2435C29.8843 13.855 30.5275 12.7677 31.3443 11.9917C32.1611 11.2158 33.3301 10.8227 34.8565 10.8227C36.9087 10.8227 38.3585 11.716 39.2059 13.5079C40.0533 15.2997 40.4719 17.6429 40.4719 20.5373V39.0682C40.4719 40.9928 40.2524 42.7284 39.8134 44.2803C39.3743 45.8271 38.6954 47.0523 37.7816 47.9457C36.8678 48.839 35.6988 49.2883 34.2694 49.2883L34.2643 49.2934ZM31.1605 42.2894C31.9569 42.2894 32.4368 41.8198 32.6052 40.8856C32.7737 39.9463 32.8554 38.7925 32.8554 37.4193V22.7018C32.8554 21.3133 32.7737 20.1545 32.6052 19.2254C32.4368 18.2963 31.962 17.8317 31.1809 17.8317C30.3999 17.8317 29.8741 18.3065 29.6954 19.2509C29.5116 20.1953 29.4248 21.349 29.4248 22.7018V37.4193C29.4248 38.7772 29.5167 39.9258 29.6954 40.8703C29.8792 41.8147 30.3642 42.2894 31.1605 42.2894Z"/>
                                    <path d="M52.3765 49.2932C46.1178 49.2932 42.9885 45.4033 42.9885 37.6183V22.5026C42.9885 18.9547 43.8206 16.1214 45.4848 14.0029C47.149 11.8844 49.4463 10.8276 52.3816 10.8276C55.3169 10.8276 57.6345 11.8844 59.2988 14.0029C60.963 16.1214 61.7951 18.9547 61.7951 22.5026V37.6183C61.7951 45.4033 58.6606 49.2932 52.3867 49.2932H52.3765ZM52.3765 42.3607C53.0044 42.3607 53.4536 42.0902 53.7293 41.5439C53.9998 41.0028 54.1377 40.3086 54.1377 39.4714V21.0681C54.1377 18.873 53.5506 17.7805 52.3765 17.7805C51.2023 17.7805 50.6153 18.8781 50.6153 21.0681V39.4765C50.6153 40.3137 50.7531 41.0028 51.0237 41.5491C51.2942 42.0953 51.7486 42.3658 52.3765 42.3658V42.3607Z"/>
                                    <path d="M70.6061 49.2935C68.9317 49.2935 67.6452 48.8749 66.7621 48.0377C65.8738 47.2005 65.2714 46.0314 64.9498 44.5357C64.6282 43.0399 64.47 41.2839 64.47 39.2725V11.2261H72.015V38.2209C72.015 39.7269 72.112 40.7785 72.306 41.3706C72.5 41.9628 72.9646 42.264 73.6895 42.264C74.4705 42.264 74.9657 41.8454 75.1648 41.0082C75.369 40.171 75.4711 39.0938 75.4711 37.7666V11.2261H82.9549V48.8902H75.4507V44.8726C74.9759 46.3122 74.4041 47.4098 73.7405 48.1602C73.0769 48.9157 72.0304 49.2884 70.6061 49.2884V49.2935Z"/>
                                    <path d="M92.4247 49.2934C90.398 49.2934 89.035 48.7778 88.3254 47.7466C87.6209 46.7154 87.2687 45.138 87.2687 43.0143V19.5368H85.1501V12.6809H87.2687V4.69678H94.5636V12.6809H96.6822V19.5368H94.5636V40.906C94.5636 41.5237 94.6453 41.9627 94.8138 42.2128C94.9822 42.463 95.3038 42.5906 95.7786 42.5906C96.1155 42.5906 96.4116 42.5549 96.6822 42.4885V48.6144C96.5137 48.6808 95.993 48.8135 95.1098 49.0024C94.2267 49.1913 93.3333 49.2934 92.4298 49.2934H92.4247Z"/>
                                    <path d="M117.786 49.2934C114.713 49.2934 112.446 48.2622 110.996 46.1947C109.541 44.1272 108.816 41.0847 108.816 37.0671V4.69678H115.984V36.7148C115.984 37.45 116.019 38.1595 116.091 38.8385C116.162 39.5174 116.321 40.0739 116.571 40.5078C116.821 40.9417 117.229 41.1612 117.786 41.1612C118.342 41.1612 118.771 40.9468 119.021 40.5231C119.271 40.0994 119.43 39.5379 119.491 38.8538C119.552 38.1698 119.583 37.4551 119.583 36.7199V4.69678H126.75V37.0671C126.75 41.0847 126.025 44.1272 124.57 46.1947C123.115 48.2622 120.854 49.2934 117.781 49.2934H117.786Z"/>
                                    <path d="M138.073 49.2934C131.87 49.2934 128.767 45.526 128.767 37.9911V35.0048H136.312V39.4256C136.312 40.2782 136.45 40.9469 136.73 41.437C137.011 41.9219 137.455 42.167 138.073 42.167C139.16 42.167 139.706 41.1868 139.706 39.2265C139.706 37.5879 139.421 36.3576 138.849 35.5459C138.277 34.7342 137.578 33.9532 136.751 33.1976L132.748 29.5068C131.493 28.3377 130.533 27.0972 129.879 25.7904C129.221 24.4835 128.894 22.6662 128.894 20.3434C128.894 18.1841 129.323 16.4024 130.181 14.9935C131.038 13.5845 132.177 12.5431 133.596 11.8539C135.015 11.1699 136.546 10.8228 138.195 10.8228C144.286 10.8228 147.333 14.4626 147.333 21.7473V22.4518H139.579V21.0224C139.579 20.2669 139.472 19.542 139.252 18.8375C139.038 18.133 138.614 17.7808 137.986 17.7808C136.924 17.7808 136.393 18.4495 136.393 19.7921C136.393 21.1347 136.843 22.2118 137.736 22.9827L142.387 27.0513C143.841 28.3071 145.056 29.7977 146.042 31.5232C147.027 33.2487 147.517 35.5408 147.517 38.4046C147.517 41.9526 146.675 44.6582 144.99 46.5164C143.305 48.3746 140.998 49.3036 138.063 49.3036L138.073 49.2934Z"/>
                                </svg>
                            </div>
                        </li>
                        <li onClick={menuClick} className="p-3 cursor-pointer">
                            <div onClick={teamScroll} className="fill-white hover:fill-red-500">
                                <svg width="90" height="54" viewBox="0 0 90 54" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.38002 48.895V13.1911H0V4.70166H16.1367V13.1911H11.7566V48.895H4.38002Z"/>
                                    <path d="M26.8672 49.2983C24.6312 49.2983 22.8496 48.798 21.5223 47.7924C20.1951 46.7867 19.2455 45.342 18.6738 43.4481C18.102 41.5542 17.8162 39.2774 17.8162 36.6177V21.4765C17.8162 17.9796 18.6534 15.3302 20.3329 13.5281C22.0073 11.7312 24.3147 10.8276 27.2501 10.8276C33.2841 10.8276 36.3062 14.3756 36.3062 21.4765V24.2127C36.3062 27.5258 36.2756 29.7516 36.2245 30.89H25.3255V38.1747C25.3255 38.8434 25.3612 39.502 25.4327 40.1452C25.5042 40.7884 25.6573 41.3193 25.9024 41.7379C26.1474 42.1565 26.5405 42.3658 27.0867 42.3658C27.8678 42.3658 28.3578 41.9626 28.5518 41.1458C28.7458 40.3341 28.8428 39.2774 28.8428 37.9705V34.3562H36.3062V36.4901C36.3062 39.3029 36.0152 41.6614 35.4383 43.5604C34.8564 45.4594 33.8813 46.8939 32.503 47.8536C31.1247 48.8185 29.2512 49.2983 26.8774 49.2983H26.8672ZM25.2745 26.4232H28.8377V21.3489C28.8377 19.991 28.6999 19.0159 28.4191 18.4238C28.1383 17.8316 27.7197 17.5304 27.1633 17.5304C26.5609 17.5304 26.0963 17.8163 25.7696 18.3829C25.4429 18.9547 25.2796 19.9399 25.2796 21.3438V26.4181L25.2745 26.4232Z"/>
                                    <path d="M44.7446 49.2981C42.9987 49.2981 41.6663 48.8131 40.7526 47.8432C39.8388 46.8733 39.2109 45.5409 38.879 43.8512C38.5421 42.1614 38.3737 40.242 38.3737 38.103C38.3737 35.8109 38.5625 33.9323 38.9403 32.4672C39.3181 31.0021 39.9613 29.8024 40.8802 28.8631C41.794 27.9238 43.0549 27.1325 44.6629 26.4791L49.3135 24.5699V20.7003C49.3135 18.6737 48.7622 17.6629 47.6595 17.6629C46.6538 17.6629 46.1485 18.4848 46.1485 20.1235V22.385H38.8535C38.8382 22.2522 38.8331 22.0787 38.8331 21.8694V21.1547C38.8331 17.4026 39.5682 14.748 41.0435 13.1808C42.5189 11.6136 44.8518 10.8325 48.0526 10.8325C49.727 10.8325 51.233 11.1899 52.5602 11.8994C53.8875 12.609 54.9391 13.6606 55.7151 15.0492C56.491 16.4377 56.879 18.153 56.879 20.1949V48.8948H49.4156V44.4229C49.0532 45.9952 48.4508 47.2 47.6136 48.0372C46.7764 48.8744 45.8166 49.293 44.7446 49.293V49.2981ZM47.7004 42.3197C48.3129 42.3197 48.7366 42.0083 48.9562 41.3906C49.1808 40.7729 49.2931 40.1041 49.2931 39.3792V28.8835C48.2568 29.3838 47.4553 30.0168 46.8836 30.7774C46.3118 31.5381 46.0259 32.6561 46.0259 34.1314V38.976C46.0259 41.2017 46.5824 42.3146 47.7004 42.3146V42.3197Z"/>
                                    <path d="M59.7735 48.895V11.2309H67.1093V15.6007C67.4462 14.0591 68.018 12.8798 68.8297 12.0579C69.6414 11.236 70.8257 10.8276 72.3929 10.8276C73.6487 10.8276 74.7207 11.1748 75.609 11.869C76.4973 12.5633 77.1201 13.4822 77.4825 14.6206C78.0543 13.3801 78.7281 12.4408 79.5041 11.7976C80.28 11.1544 81.3878 10.8327 82.8274 10.8327C84.5732 10.8327 85.9209 11.282 86.8602 12.1753C87.8047 13.0687 88.4581 14.3092 88.8307 15.8917C89.1983 17.4742 89.3872 19.3018 89.3872 21.3795V48.9001H81.9034V21.2264C81.9034 18.8015 81.3878 17.5866 80.3515 17.5866C79.8052 17.5866 79.3866 17.8112 79.0957 18.2502C78.8047 18.6943 78.6005 19.2405 78.4882 19.894C78.3759 20.5474 78.3197 21.2008 78.3197 21.8543V48.9001H70.8359V21.2264C70.8359 20.4402 70.7542 19.654 70.596 18.8679C70.4326 18.0817 70.0242 17.6887 69.3708 17.6887C68.8092 17.6887 68.3804 17.9337 68.069 18.4187C67.7627 18.9036 67.5534 19.5009 67.4411 20.2156C67.3288 20.9252 67.2727 21.6245 67.2727 22.3137V48.9052H59.7684L59.7735 48.895Z"/>
                                </svg>
                            </div>
                        </li>
                        <li onClick={menuClick} className="p-3 hover:text-red-500 cursor-pointer">
                            <div onClick={scheduleScroll} className="fill-white hover:fill-red-500">
                                <svg width="156" height="54" viewBox="0 0 156 54" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.51557 49.4921C6.14633 49.4921 3.72149 48.4864 2.23085 46.4802C0.740214 44.4739 0 41.2731 0 36.8829V32.5642H7.29493V38.0877C7.29493 39.1087 7.42256 39.9101 7.68291 40.487C7.94326 41.0638 8.39249 41.3548 9.03571 41.3548C9.67893 41.3548 10.169 41.12 10.4294 40.6503C10.6897 40.1807 10.8173 39.4099 10.8173 38.3378C10.8173 36.9799 10.705 35.8466 10.4804 34.9328C10.2558 34.0191 9.86781 33.1512 9.31648 32.3191C8.76515 31.4921 7.99941 30.5222 7.01927 29.4195L3.70617 25.6521C1.23539 22.8648 0 19.6691 0 16.0701C0 12.4712 0.730004 9.42865 2.19001 7.45304C3.65002 5.48254 5.76346 4.49219 8.53032 4.49219C11.9098 4.49219 14.3091 5.57443 15.7283 7.73381C17.1474 9.89319 17.857 13.1757 17.857 17.5761H10.3528V14.5387C10.3528 13.9363 10.2098 13.4666 9.92397 13.1348C9.63809 12.7979 9.25012 12.6345 8.76005 12.6345C8.17298 12.6345 7.74417 12.8336 7.47361 13.2267C7.20304 13.6198 7.06521 14.1252 7.06521 14.748C7.06521 15.3708 7.20304 16.0395 7.48381 16.7593C7.76459 17.4791 8.31592 18.3061 9.13781 19.2454L13.3902 24.141C14.2427 25.111 15.0238 26.1371 15.7385 27.2193C16.4532 28.3015 17.0249 29.5522 17.4588 30.9867C17.8927 32.4161 18.1072 34.162 18.1072 36.2244C18.1072 40.3747 17.469 43.6265 16.1877 45.9799C14.9115 48.3333 12.6857 49.5074 9.51047 49.5074L9.51557 49.4921Z"/>
                                    <path d="M29.8843 49.4919C26.3619 49.4919 23.8656 48.4097 22.3902 46.2503C20.9149 44.0909 20.1798 40.9207 20.1798 36.7347V25.1312C20.1798 21.9866 20.4606 19.3627 21.017 17.2696C21.5735 15.1766 22.5536 13.6145 23.9523 12.5731C25.3511 11.5368 27.291 11.0161 29.7771 11.0161C31.5076 11.0161 33.0646 11.3837 34.4379 12.1188C35.8162 12.8539 36.8984 13.9361 37.6948 15.3604C38.4912 16.7847 38.8893 18.5255 38.8893 20.5827V26.3309H31.2422V21.0575C31.2422 20.1897 31.135 19.4545 30.9257 18.8624C30.7164 18.2702 30.262 17.969 29.5627 17.969C28.3324 17.969 27.7198 19.0155 27.7198 21.1085V39.3637C27.7198 40.1346 27.8576 40.8391 28.1384 41.4874C28.4192 42.1306 28.8786 42.4522 29.5218 42.4522C30.165 42.4522 30.6347 42.1408 30.895 41.5129C31.1554 40.885 31.283 40.155 31.283 39.3178V32.9877H38.8893V39.5679C38.8893 41.6456 38.5014 43.4222 37.7254 44.9026C36.9495 46.383 35.8825 47.5163 34.5297 48.3076C33.1718 49.0937 31.625 49.4868 29.8792 49.4868L29.8843 49.4919Z"/>
                                    <path d="M41.5183 49.0888V4.89551H49.2318V15.1666C49.6351 13.7628 50.2579 12.7162 51.0951 12.0424C51.9323 11.3685 52.9125 11.0265 54.0305 11.0265C56.2103 11.0265 57.7979 11.8484 58.7883 13.4871C59.7786 15.1258 60.2738 17.3311 60.2738 20.0929V49.0939H52.6879V20.6646C52.6879 19.8427 52.5449 19.174 52.2591 18.6533C51.9732 18.1326 51.524 17.8773 50.9063 17.8773C50.3907 17.8773 49.9823 18.1479 49.6811 18.6788C49.3799 19.2148 49.2318 19.8529 49.2318 20.588V49.0888H41.5183Z"/>
                                    <path d="M72.01 49.4922C69.7741 49.4922 67.9924 48.9919 66.6652 47.9862C65.3379 46.9805 64.3884 45.5359 63.8166 43.6419C63.2449 41.748 62.959 39.4712 62.959 36.8115V21.6704C62.959 18.1735 63.7962 15.524 65.4757 13.722C67.1501 11.9251 69.4575 11.0215 72.3929 11.0215C78.4269 11.0215 81.449 14.5694 81.449 21.6704V24.4066C81.449 27.7197 81.4184 29.9454 81.3673 31.0838H70.4683V38.3685C70.4683 39.0373 70.5041 39.6958 70.5755 40.339C70.647 40.9823 70.8001 41.5132 71.0452 41.9318C71.2902 42.3504 71.6833 42.5597 72.2295 42.5597C73.0106 42.5597 73.5006 42.1564 73.6946 41.3396C73.8886 40.5279 73.9856 39.4712 73.9856 38.1644V34.5501H81.449V36.6839C81.449 39.4967 81.158 41.8552 80.5812 43.7542C79.9992 45.6533 79.0242 47.0878 77.6458 48.0475C76.2675 49.0123 74.394 49.4922 72.0202 49.4922H72.01ZM70.4173 26.617H73.9805V21.5427C73.9805 20.1848 73.8427 19.2098 73.5619 18.6176C73.2811 18.0254 72.8625 17.7242 72.3061 17.7242C71.7037 17.7242 71.2392 18.0101 70.9125 18.5768C70.5857 19.1485 70.4224 20.1338 70.4224 21.5376V26.6119L70.4173 26.617Z"/>
                                    <path d="M90.3521 49.4921C88.8972 49.4921 87.7434 49.1756 86.896 48.5375C86.0435 47.8994 85.4105 47.0213 84.9868 45.9033C84.5682 44.7803 84.2925 43.4683 84.1598 41.9623C84.0271 40.4564 83.9607 38.8381 83.9607 37.1178V20.7412C83.9607 17.8467 84.3895 15.5035 85.2471 13.7117C86.1048 11.9199 87.5648 11.0265 89.6169 11.0265C91.1382 11.0265 92.3123 11.4145 93.1291 12.1955C93.9459 12.9766 94.5789 14.0588 95.0282 15.4474V4.89551H102.655V49.0888H95.0282V45.0968C94.6096 46.4547 94.0531 47.5267 93.364 48.3129C92.6748 49.099 91.6691 49.4921 90.3572 49.4921H90.3521ZM93.221 42.4626C93.9357 42.4626 94.4105 42.1206 94.6555 41.4314C94.9005 40.7474 95.0231 39.5171 95.0231 37.7406V21.8949C95.0231 21.0066 94.9159 20.1337 94.6963 19.271C94.4819 18.4082 94.0021 17.9794 93.2619 17.9794C92.4502 17.9794 91.9397 18.3878 91.7304 19.2097C91.5211 20.0316 91.4139 20.925 91.4139 21.8949V37.7406C91.4139 40.8903 92.0163 42.4626 93.2159 42.4626H93.221Z"/>
                                    <path d="M111.604 49.4922C109.929 49.4922 108.643 49.0736 107.76 48.2364C106.872 47.3992 106.269 46.2302 105.948 44.7344C105.626 43.2387 105.468 41.4826 105.468 39.4712V11.4248H113.013V38.4196C113.013 39.9256 113.11 40.9772 113.304 41.5694C113.498 42.1615 113.962 42.4627 114.687 42.4627C115.468 42.4627 115.963 42.0441 116.163 41.2069C116.367 40.3697 116.469 39.2926 116.469 37.9653V11.4248H123.953V49.0889H116.448V45.0713C115.974 46.5109 115.402 47.6085 114.738 48.3589C114.075 49.1144 113.028 49.4871 111.604 49.4871V49.4922Z"/>
                                    <path d="M126.964 49.0888V4.89551H134.591V49.0888H126.964Z"/>
                                    <path d="M146.414 49.4922C144.178 49.4922 142.397 48.9919 141.069 47.9862C139.742 46.9805 138.793 45.5359 138.221 43.6419C137.649 41.748 137.363 39.4712 137.363 36.8115V21.6704C137.363 18.1735 138.201 15.524 139.88 13.722C141.554 11.9251 143.862 11.0215 146.797 11.0215C152.831 11.0215 155.853 14.5694 155.853 21.6704V24.4066C155.853 27.7197 155.823 29.9454 155.772 31.0838H144.873V38.3685C144.873 39.0373 144.908 39.6958 144.98 40.339C145.051 40.9823 145.204 41.5132 145.449 41.9318C145.695 42.3504 146.088 42.5597 146.634 42.5597C147.415 42.5597 147.905 42.1564 148.099 41.3396C148.293 40.5279 148.39 39.4712 148.39 38.1644V34.5501H155.853V36.6839C155.853 39.4967 155.562 41.8552 154.985 43.7542C154.404 45.6533 153.428 47.0878 152.05 48.0475C150.672 49.0123 148.798 49.4922 146.425 49.4922H146.414ZM144.822 26.617H148.385V21.5427C148.385 20.1848 148.247 19.2098 147.966 18.6176C147.685 18.0254 147.267 17.7242 146.71 17.7242C146.108 17.7242 145.643 18.0101 145.317 18.5768C144.99 19.1485 144.827 20.1338 144.827 21.5376V26.6119L144.822 26.617Z"/>
                                </svg>
                            </div>
                        </li>
                        <li onClick={menuClick} className="p-3 hover:text-red-500 cursor-pointer">
                            <div onClick={videoScroll} className="fill-white hover:fill-red-500">
                                <svg width="238" height="54" viewBox="0 0 238 54" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 47.4606V3.26221H11.3176C13.2013 3.26221 14.6307 3.7778 15.5955 4.809C16.5604 5.84019 17.2036 7.27978 17.5354 9.12776C17.8621 10.9757 18.0255 13.1504 18.0255 15.6416C18.0255 18.1328 17.7702 19.9757 17.2597 21.4153C16.7492 22.8549 15.7895 23.8504 14.3755 24.4017C15.5343 24.6876 16.3511 25.3767 16.8156 26.4743C17.2853 27.5718 17.5201 28.991 17.5201 30.7318V47.4555H10.2456V30.16C10.2456 28.8736 10.0261 28.0721 9.58704 27.7607C9.14802 27.4493 8.43843 27.2962 7.45829 27.2962V47.4606H0ZM7.50423 19.6388H9.28585C10.3068 19.6388 10.8173 18.3064 10.8173 15.6467C10.8173 13.9213 10.705 12.7931 10.4804 12.2571C10.2558 11.7211 9.83718 11.4556 9.22459 11.4556H7.50423V19.6439V19.6388Z"/>
                                    <path d="M26.0504 47.864C24.3045 47.864 22.9721 47.3791 22.0583 46.4091C21.1446 45.4392 20.5167 44.1068 20.1848 42.4171C19.8479 40.7273 19.6794 38.8079 19.6794 36.6689C19.6794 34.3768 19.8683 32.4982 20.2461 31.0331C20.6239 29.568 21.2671 28.3683 22.186 27.429C23.0997 26.4897 24.3607 25.6985 25.9687 25.045L30.6193 23.1358V19.2663C30.6193 17.2396 30.068 16.2288 28.9653 16.2288C27.9596 16.2288 27.4542 17.0507 27.4542 18.6894V20.9509H20.1593C20.144 20.8181 20.1389 20.6446 20.1389 20.4353V19.7206C20.1389 15.9685 20.874 13.3139 22.3493 11.7467C23.8246 10.1795 26.1576 9.39844 29.3584 9.39844C31.0328 9.39844 32.5387 9.75578 33.866 10.4654C35.1933 11.175 36.2449 12.2266 37.0209 13.6151C37.7968 15.0036 38.1848 16.7189 38.1848 18.7609V47.4607H30.7214V42.9888C30.3589 44.5611 29.7566 45.7659 28.9194 46.6031C28.0821 47.4403 27.1224 47.8589 26.0504 47.8589V47.864ZM29.0061 40.8856C29.6187 40.8856 30.0424 40.5742 30.2619 39.9565C30.4866 39.3388 30.5989 38.6701 30.5989 37.9452V27.4494C29.5626 27.9497 28.7611 28.5827 28.1893 29.3434C27.6176 30.104 27.3317 31.222 27.3317 32.6973V37.5419C27.3317 39.7676 27.8882 40.8805 29.0061 40.8805V40.8856Z"/>
                                    <path d="M50.5286 47.864C47.0062 47.864 44.5099 46.7817 43.0345 44.6224C41.5592 42.463 40.8241 39.2928 40.8241 35.1068V23.5033C40.8241 20.3587 41.1049 17.7347 41.6613 15.6417C42.2177 13.5487 43.1979 11.9866 44.5966 10.9452C45.9954 9.90889 47.9352 9.38818 50.4213 9.38818C52.1519 9.38818 53.7089 9.75574 55.0821 10.4908C56.4605 11.226 57.5427 12.3082 58.3391 13.7325C59.1354 15.1567 59.5336 16.8975 59.5336 18.9548V24.7029H51.8865V19.4296C51.8865 18.5617 51.7793 17.8266 51.57 17.2344C51.3607 16.6423 50.9063 16.3411 50.2069 16.3411C48.9767 16.3411 48.3641 17.3876 48.3641 19.4806V37.7358C48.3641 38.5067 48.5019 39.2111 48.7827 39.8595C49.0634 40.5027 49.5229 40.8243 50.1661 40.8243C50.8093 40.8243 51.279 40.5129 51.5393 39.885C51.7997 39.2571 51.9273 38.5271 51.9273 37.6899V31.3598H59.5336V37.94C59.5336 40.0177 59.1457 41.7942 58.3697 43.2747C57.5938 44.7551 56.5268 45.8884 55.174 46.6796C53.8161 47.4658 52.2693 47.8589 50.5234 47.8589L50.5286 47.864Z"/>
                                    <path d="M62.1217 7.48381V0H69.6259V7.48381H62.1217ZM62.1217 47.4605V9.79124H69.6259V47.4605H62.1217Z"/>
                                    <path d="M72.597 47.4607V9.79147H80.1013V14.5901C80.382 13.1505 80.9027 11.9253 81.6634 10.9095C82.424 9.89868 83.4807 9.38818 84.8386 9.38818C87.0184 9.38818 88.601 10.1897 89.5862 11.7977C90.5715 13.4058 91.0615 15.606 91.0615 18.4035V47.4556H83.6645V19.3377C83.6645 18.6026 83.5369 17.9185 83.2867 17.2906C83.0366 16.6627 82.6027 16.3513 81.985 16.3513C81.4132 16.3513 80.9895 16.5912 80.719 17.066C80.4484 17.5407 80.2748 18.138 80.2034 18.8476C80.1319 19.5572 80.0962 20.2668 80.0962 20.9713V47.4658H72.5919L72.597 47.4607Z"/>
                                    <path d="M102.466 53.9899C101.011 53.9899 99.7655 53.9031 98.7241 53.7245C97.6827 53.5509 96.7485 53.2344 95.9266 52.7852C95.0996 52.3308 94.2777 51.6978 93.4558 50.8759L95.9929 45.6791C97.8256 46.986 99.6532 47.6394 101.486 47.6394C102.686 47.6394 103.584 47.18 104.166 46.256C104.753 45.3371 105.044 44.1374 105.044 42.6672V38.9764C104.442 41.5543 102.844 42.8459 100.245 42.8459C98.7496 42.8459 97.5397 42.4171 96.6209 41.5645C95.702 40.712 95.0281 39.5532 94.6095 38.0983C94.1909 36.6434 93.9816 35.02 93.9816 33.2282V19.6951C93.9816 17.6684 94.1654 15.8817 94.5278 14.3349C94.8903 12.7881 95.5284 11.5782 96.4371 10.7053C97.3458 9.83746 98.6169 9.39844 100.25 9.39844C101.634 9.39844 102.711 9.81704 103.487 10.6542C104.263 11.4915 104.784 12.6196 105.049 14.0439V9.80173H112.696V42.0955C112.696 46.3632 111.874 49.4159 110.236 51.2486C108.592 53.0812 106.004 54.0001 102.471 54.0001L102.466 53.9899ZM103.283 36.0614C104.457 36.0614 105.044 34.7903 105.044 32.243V19.4347C105.044 18.7507 104.881 18.0462 104.554 17.3264C104.227 16.6066 103.773 16.2441 103.201 16.2441C102.476 16.2441 102.022 16.5657 101.838 17.209C101.654 17.8522 101.568 18.5924 101.568 19.4296V32.2379C101.568 33.1414 101.659 34.0042 101.848 34.8261C102.037 35.6479 102.517 36.0563 103.283 36.0563V36.0614Z"/>
                                    <path d="M129.206 47.4605L124.657 3.2417H131.676L134.382 33.325L136.791 3.2417H143.811L139.262 47.4605H129.2H129.206Z"/>
                                    <path d="M145.786 7.48381V0H153.291V7.48381H145.786ZM145.786 47.4605V9.79124H153.291V47.4605H145.786Z"/>
                                    <path d="M162.449 47.8639C160.994 47.8639 159.84 47.5474 158.993 46.9093C158.14 46.2712 157.507 45.3931 157.083 44.2751C156.665 43.1521 156.389 41.8401 156.256 40.3341C156.124 38.8282 156.057 37.2099 156.057 35.4896V19.1079C156.057 16.2134 156.486 13.8702 157.344 12.0784C158.201 10.2866 159.661 9.39322 161.714 9.39322C163.235 9.39322 164.409 9.78119 165.226 10.5622C166.043 11.3433 166.676 12.4255 167.125 13.8141V3.26221H174.752V47.4606H167.125V43.4686C166.706 44.8265 166.15 45.8985 165.461 46.6847C164.771 47.4708 163.766 47.8639 162.454 47.8639H162.449ZM165.318 40.8344C166.032 40.8344 166.507 40.4924 166.752 39.8032C166.997 39.1192 167.12 37.8889 167.12 36.1124V20.2667C167.12 19.3784 167.013 18.5055 166.793 17.6428C166.579 16.78 166.099 16.3512 165.359 16.3512C164.547 16.3512 164.036 16.7596 163.827 17.5815C163.618 18.4034 163.511 19.2968 163.511 20.2667V36.1124C163.511 39.2621 164.113 40.8344 165.313 40.8344H165.318Z"/>
                                    <path d="M186.442 47.8638C184.206 47.8638 182.424 47.3635 181.097 46.3578C179.77 45.3521 178.82 43.9074 178.249 42.0135C177.677 40.1196 177.391 37.8428 177.391 35.1831V20.0419C177.391 16.5451 178.228 13.8956 179.908 12.0936C181.582 10.2966 183.889 9.39307 186.825 9.39307C192.859 9.39307 195.881 12.941 195.881 20.0419V22.7782C195.881 26.0913 195.85 28.317 195.799 29.4554H184.9V36.7401C184.9 37.4089 184.936 38.0674 185.007 38.7106C185.079 39.3539 185.232 39.8848 185.477 40.3034C185.722 40.722 186.115 40.9313 186.661 40.9313C187.442 40.9313 187.933 40.528 188.127 39.7112C188.321 38.8995 188.418 37.8428 188.418 36.5359V32.9216H195.881V35.0555C195.881 37.8683 195.59 40.2268 195.013 42.1258C194.431 44.0249 193.456 45.4593 192.078 46.4191C190.699 47.3839 188.826 47.8638 186.452 47.8638H186.442ZM184.849 24.9886H188.412V19.9143C188.412 18.5564 188.275 17.5814 187.994 16.9892C187.713 16.397 187.294 16.0958 186.738 16.0958C186.136 16.0958 185.671 16.3817 185.344 16.9484C185.018 17.5201 184.854 18.5054 184.854 19.9092V24.9835L184.849 24.9886Z"/>
                                    <path d="M207.735 47.864C201.476 47.864 198.347 43.9741 198.347 36.1891V21.0734C198.347 17.5255 199.179 14.6922 200.843 12.5737C202.507 10.4552 204.804 9.39844 207.74 9.39844C210.675 9.39844 212.993 10.4552 214.657 12.5737C216.321 14.6922 217.153 17.5255 217.153 21.0734V36.1891C217.153 43.9741 214.019 47.864 207.745 47.864H207.735ZM207.735 40.9315C208.363 40.9315 208.812 40.661 209.087 40.1148C209.358 39.5736 209.496 38.8794 209.496 38.0422V19.6389C209.496 17.4438 208.909 16.3513 207.735 16.3513C206.56 16.3513 205.973 17.4489 205.973 19.6389V38.0473C205.973 38.8845 206.111 39.5736 206.382 40.1199C206.652 40.6661 207.107 40.9366 207.735 40.9366V40.9315Z"/>
                                    <path d="M228.527 47.8638C222.324 47.8638 219.221 44.0963 219.221 36.5615V33.5751H226.766V37.9959C226.766 38.8485 226.903 39.5172 227.184 40.0073C227.465 40.4922 227.909 40.7373 228.527 40.7373C229.614 40.7373 230.16 39.7571 230.16 37.7968C230.16 36.1582 229.875 34.9279 229.303 34.1162C228.731 33.3045 228.032 32.5235 227.205 31.7679L223.202 28.0771C221.947 26.9081 220.987 25.6676 220.333 24.3607C219.675 23.0538 219.348 21.2365 219.348 18.9137C219.348 16.7544 219.777 14.9727 220.635 13.5638C221.492 12.1548 222.631 11.1134 224.05 10.4243C225.469 9.7402 227 9.39307 228.649 9.39307C234.74 9.39307 237.787 13.0329 237.787 20.3176V21.0221H230.033V19.5927C230.033 18.8372 229.926 18.1123 229.706 17.4078C229.492 16.7033 229.068 16.3511 228.44 16.3511C227.378 16.3511 226.847 17.0198 226.847 18.3624C226.847 19.705 227.297 20.7821 228.19 21.553L232.841 25.6216C234.295 26.8774 235.51 28.3681 236.496 30.0935C237.481 31.819 237.971 34.1111 237.971 36.975C237.971 40.5229 237.129 43.2285 235.444 45.0867C233.759 46.9449 231.452 47.874 228.517 47.874L228.527 47.8638Z"/>
                                </svg>
                            </div>
                        </li>
                        <li onClick={menuClick} className="p-3 hover:text-red-500 cursor-pointer">
                            <div onClick={galleryScroll} className="fill-white hover:fill-red-500">
                                <svg width="117" height="54" viewBox="0 0 117 54" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.90242 46.5619C2.63414 46.5619 0 42.044 0 33.0032V13.9414C0 5.68679 3.01701 1.56201 9.05613 1.56201C11.5422 1.56201 13.4515 2.15929 14.7787 3.35894C16.106 4.5586 17.0198 6.29938 17.5252 8.59659C18.0306 10.8887 18.2807 13.6862 18.2807 16.984H11.0726V13.0685C11.0726 12.1139 10.9654 11.3175 10.7459 10.6692C10.5315 10.026 10.0771 9.70436 9.39305 9.70436C8.54053 9.70436 7.97899 10.0464 7.70843 10.7356C7.43787 11.4247 7.30004 12.1751 7.30004 12.997V34.4173C7.30004 35.6067 7.42766 36.5716 7.68801 37.3169C7.94836 38.0622 8.45375 38.4349 9.20928 38.4349C9.96481 38.4349 10.511 38.0622 10.7714 37.3169C11.0317 36.5716 11.1594 35.5863 11.1594 34.3662V28.0617H9.18886V20.4298H18.199V46.1688H15.2433L13.9875 42.4524C12.701 45.1989 10.6744 46.5721 7.90752 46.5721L7.90242 46.5619Z"/>
                                    <path d="M26.6988 46.5618C24.9529 46.5618 23.6206 46.0768 22.7068 45.1069C21.793 44.1369 21.1651 42.8046 20.8333 41.1148C20.4963 39.4251 20.3279 37.5056 20.3279 35.3667C20.3279 33.0746 20.5168 31.196 20.8945 29.7308C21.2723 28.2657 21.9155 27.0661 22.8344 26.1268C23.7482 25.1875 25.0091 24.3962 26.6171 23.7428L31.2677 21.8335V17.964C31.2677 15.9374 30.7164 14.9266 29.6137 14.9266C28.6081 14.9266 28.1027 15.7485 28.1027 17.3871V19.6486H20.8077C20.7924 19.5159 20.7873 19.3423 20.7873 19.133V18.4183C20.7873 14.6662 21.5224 12.0117 22.9978 10.4445C24.4731 8.87724 26.806 8.09619 30.0068 8.09619C31.6812 8.09619 33.1872 8.45354 34.5145 9.16312C35.8417 9.8727 36.8934 10.9243 37.6693 12.3129C38.4452 13.7014 38.8332 15.4166 38.8332 17.4586V46.1585H31.3698V41.6866C31.0074 43.2589 30.405 44.4636 29.5678 45.3009C28.7306 46.1381 27.7709 46.5567 26.6988 46.5567V46.5618ZM29.6546 39.5833C30.2672 39.5833 30.6909 39.2719 30.9104 38.6543C31.135 38.0366 31.2473 37.3678 31.2473 36.6429V26.1472C30.211 26.6475 29.4095 27.2805 28.8378 28.0411C28.266 28.8018 27.9802 29.9197 27.9802 31.3951V36.2396C27.9802 38.4654 28.5366 39.5782 29.6546 39.5782V39.5833Z"/>
                                    <path d="M41.7277 46.1586V1.96533H49.3544V46.1586H41.7277Z"/>
                                    <path d="M52.3766 46.1586V1.96533H60.0033V46.1586H52.3766Z"/>
                                    <path d="M71.8212 46.562C69.5852 46.562 67.8036 46.0617 66.4763 45.056C65.149 44.0504 64.1995 42.6057 63.6278 40.7118C63.056 38.8178 62.7701 36.541 62.7701 33.8814V18.7402C62.7701 15.2433 63.6073 12.5938 65.2869 10.7918C66.9613 8.99488 69.2687 8.09131 72.204 8.09131C78.2381 8.09131 81.2602 11.6392 81.2602 18.7402V21.4764C81.2602 24.7895 81.2295 27.0153 81.1785 28.1537H70.2795V35.4384C70.2795 36.1071 70.3152 36.7657 70.3867 37.4089C70.4582 38.0521 70.6113 38.583 70.8563 39.0016C71.1014 39.4202 71.4945 39.6295 72.0407 39.6295C72.8217 39.6295 73.3118 39.2262 73.5058 38.4094C73.6998 37.5978 73.7968 36.541 73.7968 35.2342V31.6199H81.2602V33.7537C81.2602 36.5666 80.9692 38.925 80.3923 40.8241C79.8104 42.7231 78.8353 44.1576 77.457 45.1173C76.0787 46.0821 74.2052 46.562 71.8314 46.562H71.8212ZM70.2284 23.6868H73.7917V18.6126C73.7917 17.2546 73.6538 16.2796 73.3731 15.6874C73.0923 15.0953 72.6737 14.7941 72.1173 14.7941C71.5149 14.7941 71.0503 15.0799 70.7236 15.6466C70.3969 16.2183 70.2335 17.2036 70.2335 18.6074V23.6817L70.2284 23.6868Z"/>
                                    <path d="M83.9811 46.1585V8.49438H91.6078V12.6855C91.9703 11.2306 92.6135 10.0922 93.5375 9.27033C94.4615 8.44843 95.6203 8.04004 97.0191 8.04004V15.5494C96.4065 15.5494 95.6714 15.6311 94.8188 15.7893C93.9663 15.9476 93.221 16.1671 92.5778 16.4427C91.9346 16.7184 91.6129 17.0247 91.6129 17.3616V46.1636H83.9862L83.9811 46.1585Z"/>
                                    <path d="M98.2494 52.4375V46.7353H101.853C102.4 46.7353 102.67 46.5107 102.67 46.0564C102.67 45.8216 102.65 45.5816 102.609 45.3264L97.6827 8.48926H104.87L106.969 36.2141L109.439 8.48926H116.668L110.843 48.1137C110.634 49.5379 110.231 50.61 109.638 51.34C109.046 52.07 108.071 52.4324 106.713 52.4324H98.2443L98.2494 52.4375Z"/>
                                </svg>
                            </div>
                        </li>
                        <li onClick={menuClick} className="p-3 hover:text-red-500 cursor-pointer">
                            <div onClick={contactScroll} className="fill-white hover:fill-red-500">
                                <svg width="180" height="54" viewBox="0 0 180 54" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.88259 49.495C6.21272 49.495 4.06354 48.4995 2.44018 46.5086C0.81171 44.5177 2.7348e-05 41.7304 2.7348e-05 38.1468V17.7526C-0.00507757 13.4696 0.704507 10.1871 2.12367 7.91031C3.53774 5.63351 5.85027 4.49512 9.05105 4.49512C10.7969 4.49512 12.3539 4.88309 13.7118 5.64883C15.0749 6.41967 16.1469 7.56828 16.9279 9.09976C17.709 10.6312 18.1021 12.5609 18.1021 14.8887V22.5206H10.7663V15.9914C10.7663 14.6692 10.6285 13.7759 10.3477 13.3164C10.0669 12.857 9.63301 12.6273 9.04595 12.6273C8.36189 12.6273 7.90245 12.9233 7.66251 13.5206C7.42258 14.1128 7.30517 14.9041 7.30517 15.8944V38.0191C7.30517 39.2392 7.45321 40.1019 7.7544 40.6073C8.05559 41.1076 8.48441 41.3629 9.04084 41.3629C9.66875 41.3629 10.1129 41.0515 10.3732 40.4338C10.6336 39.8161 10.7612 39.0095 10.7612 38.0242V30.0657H18.1787V38.4275C18.1787 42.3124 17.3619 45.1303 15.7283 46.8762C14.0947 48.6272 11.8077 49.5001 8.87749 49.5001L8.88259 49.495Z"/>
                                    <path d="M29.6493 49.4949C23.3907 49.4949 20.2614 45.6049 20.2614 37.8199V22.7043C20.2614 19.1563 21.0935 16.3231 22.7577 14.2046C24.4219 12.086 26.7191 11.0293 29.6544 11.0293C32.5897 11.0293 34.9074 12.086 36.5716 14.2046C38.2358 16.3231 39.0679 19.1563 39.0679 22.7043V37.8199C39.0679 45.6049 35.9335 49.4949 29.6595 49.4949H29.6493ZM29.6493 42.5624C30.2772 42.5624 30.7264 42.2918 31.0021 41.7456C31.2727 41.2045 31.4105 40.5102 31.4105 39.673V21.2698C31.4105 19.0747 30.8234 17.9822 29.6493 17.9822C28.4752 17.9822 27.8881 19.0798 27.8881 21.2698V39.6781C27.8881 40.5153 28.0259 41.2045 28.2965 41.7507C28.5671 42.2969 29.0214 42.5675 29.6493 42.5675V42.5624Z"/>
                                    <path d="M41.8297 49.0918V11.4277H49.3339V16.2263C49.6147 14.7867 50.1354 13.5616 50.896 12.5457C51.6567 11.5349 52.7134 11.0244 54.0713 11.0244C56.2511 11.0244 57.8336 11.8259 58.8189 13.4339C59.8041 15.042 60.2942 17.2422 60.2942 20.0397V49.0918H52.8972V20.9688C52.8972 20.2337 52.7695 19.5496 52.5194 18.9217C52.2693 18.2938 51.8353 17.9824 51.2176 17.9824C50.6459 17.9824 50.2222 18.2224 49.9516 18.6971C49.6811 19.1719 49.5075 19.7691 49.436 20.4787C49.3646 21.1883 49.3288 21.8979 49.3288 22.6024V49.0969H41.8246L41.8297 49.0918Z"/>
                                    <path d="M69.6822 49.495C67.6556 49.495 66.2926 48.9794 65.583 47.9482C64.8785 46.917 64.5263 45.3396 64.5263 43.216V19.7384H62.4077V12.8825H64.5263V4.89844H71.8212V12.8825H73.9397V19.7384H71.8212V41.1076C71.8212 41.7253 71.9029 42.1644 72.0713 42.4145C72.2398 42.6646 72.5614 42.7923 73.0362 42.7923C73.3731 42.7923 73.6692 42.7565 73.9397 42.6902V48.8161C73.7713 48.8824 73.2506 49.0152 72.3674 49.2041C71.4843 49.3929 70.5909 49.495 69.6873 49.495H69.6822Z"/>
                                    <path d="M82.0462 49.4949C80.3003 49.4949 78.968 49.0099 78.0542 48.04C77.1404 47.07 76.5125 45.7377 76.1807 44.0479C75.8438 42.3582 75.6753 40.4388 75.6753 38.2998C75.6753 36.0077 75.8642 34.1291 76.2419 32.664C76.6197 31.1988 77.2629 29.9992 78.1818 29.0599C79.0956 28.1206 80.3565 27.3293 81.9646 26.6759L86.6151 24.7666V20.8971C86.6151 18.8705 86.0638 17.8597 84.9611 17.8597C83.9555 17.8597 83.4501 18.6816 83.4501 20.3203V22.5817H76.1552C76.1398 22.449 76.1347 22.2754 76.1347 22.0661V21.3514C76.1347 17.5993 76.8698 14.9448 78.3452 13.3776C79.8205 11.8103 82.1534 11.0293 85.3542 11.0293C87.0286 11.0293 88.5346 11.3866 89.8619 12.0962C91.1891 12.8058 92.2408 13.8574 93.0167 15.246C93.7927 16.6345 94.1806 18.3498 94.1806 20.3917V49.0916H86.7172V44.6197C86.3548 46.192 85.7524 47.3968 84.9152 48.234C84.078 49.0712 83.1183 49.4898 82.0462 49.4898V49.4949ZM85.002 42.5165C85.6146 42.5165 86.0383 42.2051 86.2578 41.5874C86.4824 40.9697 86.5947 40.3009 86.5947 39.576V29.0803C85.5584 29.5806 84.7569 30.2136 84.1852 30.9742C83.6134 31.7349 83.3276 32.8528 83.3276 34.3282V39.1727C83.3276 41.3985 83.884 42.5113 85.002 42.5113V42.5165Z"/>
                                    <path d="M106.524 49.4948C103.002 49.4948 100.506 48.4126 99.0304 46.2532C97.5551 44.0938 96.8199 40.9237 96.8199 36.7376V25.1342C96.8199 21.9895 97.1007 19.3656 97.6572 17.2726C98.2136 15.1796 99.1937 13.6174 100.592 12.576C101.991 11.5397 103.931 11.019 106.417 11.019C108.148 11.019 109.705 11.3866 111.078 12.1217C112.456 12.8568 113.539 13.9391 114.335 15.3633C115.131 16.7876 115.529 18.5284 115.529 20.5857V26.3338H107.882V21.0604C107.882 20.1926 107.775 19.4575 107.566 18.8653C107.357 18.2731 106.902 17.9719 106.203 17.9719C104.973 17.9719 104.36 19.0185 104.36 21.1115V39.3667C104.36 40.1375 104.498 40.842 104.779 41.4903C105.059 42.1335 105.519 42.4552 106.162 42.4552C106.805 42.4552 107.275 42.1437 107.535 41.5158C107.796 40.8879 107.923 40.1579 107.923 39.3207V32.9906H115.529V39.5709C115.529 41.6486 115.142 43.4251 114.366 44.9055C113.59 46.3859 112.523 47.5192 111.17 48.3105C109.812 49.0967 108.265 49.4897 106.519 49.4897L106.524 49.4948Z"/>
                                    <path d="M124.611 49.495C122.585 49.495 121.222 48.9794 120.512 47.9482C119.807 46.917 119.455 45.3396 119.455 43.216V19.7384H117.337V12.8825H119.455V4.89844H126.75V12.8825H128.869V19.7384H126.75V41.1076C126.75 41.7253 126.832 42.1644 127 42.4145C127.169 42.6646 127.49 42.7923 127.965 42.7923C128.302 42.7923 128.598 42.7565 128.869 42.6902V48.8161C128.7 48.8824 128.18 49.0152 127.296 49.2041C126.413 49.3929 125.52 49.495 124.616 49.495H124.611Z"/>
                                    <path d="M149.972 49.495C146.899 49.495 144.633 48.4638 143.183 46.3963C141.728 44.3288 141.003 41.2863 141.003 37.2687V4.89844H148.17V36.9114C148.17 37.6465 148.206 38.3561 148.278 39.035C148.349 39.714 148.507 40.2704 148.757 40.7044C149.008 41.1383 149.416 41.3578 149.972 41.3578C150.529 41.3578 150.958 41.1434 151.208 40.7197C151.458 40.296 151.616 39.7344 151.677 39.0504C151.739 38.3663 151.769 37.6516 151.769 36.9165V4.89844H158.937V37.2687C158.937 41.2863 158.212 44.3288 156.757 46.3963C155.302 48.4638 153.04 49.495 149.967 49.495H149.972Z"/>
                                    <path d="M170.259 49.4951C164.057 49.4951 160.953 45.7277 160.953 38.1928V35.2064H168.498V39.6273C168.498 40.4798 168.636 41.1486 168.917 41.6386C169.198 42.1236 169.642 42.3686 170.259 42.3686C171.347 42.3686 171.893 41.3885 171.893 39.4282C171.893 37.7895 171.607 36.5592 171.035 35.7475C170.464 34.9359 169.764 34.1548 168.937 33.3993L164.935 29.7084C163.679 28.5394 162.719 27.2989 162.066 25.992C161.407 24.6852 161.081 22.8678 161.081 20.5451C161.081 18.3857 161.51 16.6041 162.367 15.1951C163.225 13.7862 164.363 12.7448 165.782 12.0556C167.202 11.3715 168.733 11.0244 170.382 11.0244C176.472 11.0244 179.52 14.6642 179.52 21.9489V22.6534H171.765V21.224C171.765 20.4685 171.658 19.7436 171.439 19.0391C171.224 18.3347 170.801 17.9824 170.173 17.9824C169.111 17.9824 168.58 18.6512 168.58 19.9938C168.58 21.3364 169.029 22.4135 169.922 23.1843L174.573 27.253C176.028 28.5088 177.243 29.9994 178.228 31.7249C179.213 33.4503 179.704 35.7424 179.704 38.6063C179.704 42.1542 178.861 44.8598 177.177 46.718C175.492 48.5762 173.185 49.5053 170.249 49.5053L170.259 49.4951Z"/>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
    )
};

export default Header;