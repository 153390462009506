import React from "react";
import { Link, useParams } from "react-router-dom";

const ImgDetailPage = (props) => {
  const { imgNum } = useParams();

  return (
    <main className="bg-black overflow-hidden">
      <div className="w-full h-screen my-center">
        <div className="my-center max-w-[1000px] relative">
          <img
            className="w-full h-auto object-contain max-h-[800px]"
            src={`/raceImg/${imgNum.slice(1)}.png`}
            alt=""
          />
          <div className="absolute top-0 right-0 bg-white hover:text-white hover:bg-red-500 w-[24px] h-[24px] text-black text-xl rounded-full my-center m-[20px] font-thin">
            <Link to={`/home`}>X</Link>
          </div>
          <div className="absolute  right-0 hover:text-white w-[24px] h-[24px] text-5xl rounded-full my-center m-[20px] text-gray-300">
            <Link
              to={`/img/:${
                parseInt(imgNum.slice(1)) < 15
                  ? parseInt(imgNum.slice(1)) + 1
                  : 1
              }`}
            >
              &gt;
            </Link>
          </div>
          <div className="absolute left-0 hover:text-white w-[24px] h-[24px] text-5xl rounded-full my-center m-[20px] text-gray-300">
            <Link
              to={`/img/:${
                parseInt(imgNum.slice(1)) > 1
                  ? parseInt(imgNum.slice(1)) - 1
                  : 10
              }`}
            >
              &lt;
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ImgDetailPage;
