import React from 'react';

const Mechanic = ( {img, name, position, goal} ) => {
    return(
        <li className="w-full bg-[#1F1F1F] my-[20px] mr-[20px] md:w-[220px]  xl:w-[340px] relative">
            <div className="">
                <img className="w-full h-auto" src={img} alt=""/>
            </div>
            <div className="h-[105px] p-[24px]">
                <h3 className=" text-[24px] font-bold">{name}</h3>
                <span className="text-[18px] text-[#D9D9D9]">{position}</span>
            </div>
            <div className="absolute bg-[#00ff0000] text-[#00ff0000] hover:text-white hover:bg-[#1F1F1F80] top-0 py-6 px-[22px] w-full h-full xl:p-10">
                <div className="font-bold mb-[10px] md:text-[24px] xl:text-[28px] xl:mb-[20px]">
                    {name}
                </div>
                <div className="text-[18px] mb-5">
                    메카닉
                </div>
                <ul className="text-[12px] md:text-[14px]">
                    {goal.map((x)=><li>{x}</li>)}
                </ul>
            </div>
        </li>
    )
};

export default Mechanic;