import React from "react";
import ReactPlayer from "react-player";
import textCircle from "../../asset/redCircle.png";
import thumbnail from "../../asset/img/mmx202301.jpeg";

const VideoSection = ({ videoRef }) => {
  return (
    <section ref={videoRef}>
      <div className="w-full h-auto">
        <div className="w-full h-auto h-[500px] xl:h-screen my-center">
          <ReactPlayer
            // url="/videoSectionVideo2.mp4"
            url="/mmx2023.mp4"
            width="100%"
            height="100%"
            playing={true}
            controls={true}
            playIcon={
              <button className="text-5xl text-red-500 relative">
                <div>
                  ▶︎
                  <div className="absolute w-32 animate-spin-slow -top-10 -right-10 md:-right-10">
                    <img className="w-full full" src={textCircle} alt="" />
                  </div>
                </div>
              </button>
            }
            light={thumbnail}
          />
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
