import React from 'react';
import logo from '../asset/logos/mmxMotersMainLogo.png';

const StartPage = (props) => {
    return (
        <main className="w-full h-screen bg-black my-center relative overflow-hidden">
            <div className="animate-popUp">
                <img src={logo} alt=""/>
            </div>
            <div className="absolute w-full bg-[#D70C0C] h-screen top-full animate-goUp"> </div>
            <div className="absolute w-full bg-black h-screen top-full animate-goUp2"> </div>
        </main>
    )
};

export default StartPage;