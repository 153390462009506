import React, { useRef } from "react";
import HomeDetail1 from "./homeDetail1";
import HomeDetail2 from "./homeDetail2";
import HomeDetail3 from "./homeDetail3";
import VideoSection from "./videoSection";
import ImgSection from "./imgSection";
import Header from "../../components/header";
import ContactUs from "./contactUs";
import Footer from "../../components/footer";
import MechPage from "../../components/mechPage";
import Marquee from "react-fast-marquee";

const Home = () => {
  const home1Ref = useRef(null);
  const teamRef = useRef(null);
  const scheduleRef = useRef(null);
  const videoRef = useRef(null);
  const galleryRef = useRef(null);
  const contactRef = useRef(null);
  const home1Scroll = () => home1Ref.current.scrollIntoView();
  const teamScroll = () => teamRef.current.scrollIntoView();
  const scheduleScroll = () => scheduleRef.current.scrollIntoView();
  const videoScroll = () => videoRef.current.scrollIntoView();
  const galleryScroll = () => galleryRef.current.scrollIntoView();
  const contactScroll = () => contactRef.current.scrollIntoView();

  return (
    <main className="bg-black text-white h-auto">
      <VideoSection videoRef={videoRef} />
      <Header
        home1Scroll={home1Scroll}
        teamScroll={teamScroll}
        scheduleScroll={scheduleScroll}
        videoScroll={videoScroll}
        galleryScroll={galleryScroll}
        contactScroll={contactScroll}
      />
      <HomeDetail1 home1Ref={home1Ref} />
      <HomeDetail2 teamRef={teamRef} />
      <MechPage />
      <Marquee gradient={false} speed={80} pauseOnHover={false}>
        <div className="hidden md:flex text-[96px] overflow-hidden font-bold md:mb-10 w-full xl:mt-10 xl:mb-24 font-anton w-full">
          MMX MOTORSPORTS RACING & REPAIR SHOP
        </div>
      </Marquee>
      <HomeDetail3 scheduleRef={scheduleRef} />
      <ImgSection galleryRef={galleryRef} />
      <ContactUs contactRef={contactRef} />
      <Footer
        home1Scroll={home1Scroll}
        teamScroll={teamScroll}
        scheduleScroll={scheduleScroll}
        videoScroll={videoScroll}
        galleryScroll={galleryScroll}
        contactScroll={contactScroll}
      />
    </main>
  );
};

export default Home;
