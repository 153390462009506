import React from 'react';
import roundImg1 from '../../asset/img/2023/round_schedule_01.jpg'
import roundImg2 from '../../asset/img/2023/round_schedule_02.jpg'
import roundImg3 from '../../asset/img/2023/round_schedule_03.jpg'
import roundImg4 from '../../asset/img/2023/round_schedule_04.jpg'
import roundImg5 from '../../asset/img/2023/round_schedule_05.jpg'
import roundImg6 from '../../asset/img/2023/round_schedule_06.jpg'
import roundImg7 from '../../asset/img/2023/round_schedule_07.jpg'
import roundImg8 from '../../asset/img/2023/round_schedule_08.jpg'
import Round from "../../components/round";


const HomeDetail3 = ({ scheduleRef}) => {
    return (
        <section className="xl:my-center xl:bg-raceBG xl:bg-contain">
            <div className="xl:w-[1279px]">
                <h3 ref={scheduleRef} className="text-3xl mb-5 mx-[20px] font-bold md:mx-[40px] md:text-[28px] md:mb-[60px] xl:text-[40px]">
                    2023 레이싱 스케줄
                </h3>
                <ul className="md:flex md:flex-wrap md:my-center">
                    <Round img={roundImg1} title="Round. 1" track="용인 에버랜드 스피드웨이" date="04.22-23" url="http://www.super-race.com/ticket/calendar_2023.jsp?rid=55"/>
                    <Round img={roundImg2} title="Round. 2" track="용인 에버랜드 스피드웨이" date="04.22-23" url="http://www.super-race.com/ticket/calendar_2023.jsp?rid=56"/>
                    <Round img={roundImg3} title="Round. 3" track="영암 코리아 인터네셔널 서킷" date="06.04" url="http://www.super-race.com/ticket/calendar_2023.jsp?rid=57"/>
                    <Round img={roundImg4} title="Round. 4" track="인제 스피디움" date="07.08" url="http://www.super-race.com/ticket/calendar_2023.jsp?rid=58"/>
                    <Round img={roundImg5} title="Round. 5" track="용인 에버랜드 스피드웨이" date="08.19-20" url="http://www.super-race.com/ticket/calendar_2023.jsp?rid=59"/>
                    <Round img={roundImg6} title="Round. 6" track="영암 코리아 인터네셔널 서킷" date="10.14-15" url="http://www.super-race.com/ticket/calendar_2023.jsp?rid=60"/>
                    <Round img={roundImg7} title="Round. 7" track="용인 에버랜드 스피드웨이" date="11.04" url="http://www.super-race.com/ticket/calendar_2023.jsp?rid=61"/>
                    <Round img={roundImg8} title="Round. 8" track="용인 에버랜드 스피드웨이" date="11.05" url="http://www.super-race.com/ticket/calendar_2023.jsp?rid=62"/>
                </ul>
                <div className="hidden xl:flex xl:p-5 xl:pb-20 xl:ml-10">
                    * 상기 일정은 주최측의 사정으로 변경될 수 있습니다.
                </div>
            </div>
        </section>
    )
};

export default HomeDetail3;