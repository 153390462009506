import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ImgComp from "../../components/imgComp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImgSection = ({ galleryRef }) => {
  const [galleryWidth, setGalleryWidth] = useState(null);

  useEffect(() => setGalleryWidth(galleryRef?.current?.offsetWidth), []);

  const sliderSettingHandler = () => {
    if (galleryWidth < 768) {
      return {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        autoplaySpeed: 4000,
        arrows: true,
      };
    } else if (galleryWidth < 1280) {
      return {
        swipe: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        arrows: true,
      };
    } else {
      return {
        dots: false,
        swipe: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        arrows: true,
      };
    }
  };

  return (
    <section ref={galleryRef} className="xl:my-center w-full">
      <div className="xl:w-[1279px] xl:my-[60px] pb-20">
        <div className="p-[20px] pt-20 pb-[40px] md:p-10 md:flex md:justify-between">
          <h2 className="text-[30px] leading-9 font-bold leading-[28px] xl:text-[40px] xl:leading-[48px] xl:mr-[310px]">
            it’s not only about racing
            <br />
            it's the adventure
          </h2>
        </div>
        <ul className="w-full h-[500px] md:h-[600px] pt-10 px-8 xl:pt-20">
          <Slider {...sliderSettingHandler()}>
            <ImgComp img="bg-raceImg1" imgNum="1" detail3="" />
            <ImgComp img="bg-raceImg2" imgNum="2" detail3="" />
            <ImgComp img="bg-raceImg3" imgNum="3" detail3="" />
            <ImgComp img="bg-raceImg4" imgNum="4" detail3="" />
            <ImgComp img="bg-raceImg5" imgNum="5" detail3="" />
            <ImgComp img="bg-raceImg6" imgNum="6" detail3="" />
            <ImgComp img="bg-raceImg7" imgNum="7" detail3="" />
            <ImgComp img="bg-raceImg8" imgNum="8" detail3="" />
            <ImgComp img="bg-raceImg9" imgNum="9" detail3="" />
            <ImgComp img="bg-raceImg10" imgNum="10" detail3="" />
            <ImgComp img="bg-raceImg11" imgNum="11" detail3="" />
            <ImgComp img="bg-raceImg12" imgNum="12" detail3="" />
            <ImgComp img="bg-raceImg13" imgNum="13" detail3="" />
            <ImgComp img="bg-raceImg14" imgNum="14" detail3="" />
            <ImgComp img="bg-raceImg15" imgNum="15" detail3="" />
          </Slider>
        </ul>
      </div>
    </section>
  );
};

export default ImgSection;
