import React from "react";
import { Link } from "react-router-dom";

const ImgComp = ({ img, imgNum, detail3 }) => {
  return (
    <li className="my-center p-5 relative">
      <div
        className={`${img} bg-center bg-cover w-[240px] h-[400px] md:w-[220px] xl:w-[220px]`}
      >
        <div className="bg-[#1F1F1F80] w-[240px] h-[400px] flex items-end relative md:w-[220px] xl:w-[220px]">
          <div className="absolute top-0 right-0 bg-white hover:text-white hover:bg-red-500 w-[24px] h-[24px] text-black text-xl rounded-full my-center m-[20px] font-thin">
            <Link to={`/img/:${imgNum}`}>+</Link>
          </div>
          <div className="p-[20px]">
            <div className="text-[20px] font-bold mb-[10px]">CJ SUPERRACE</div>
            <div className="text-[14px]">{detail3}</div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ImgComp;
