import React from "react";
import Mechanic from "./mechanic";
import mechImg1 from "../asset/img/조성현.png";
import mechImg2 from "../asset/img/강문헌.png";
import mechImg3 from "../asset/img/한준희.png";

const MechPage = () => {
  return (
    <div>
      <section className="xl:my-center">
        <div className="xl:w-[1360px] xl:flex-col xl:flex xl:justify-start md:p-[5px]">
          <div className="text-[24px] md:pt-[60px] lx:my-center pb-[100px]">
            <h3 className="font-bold text-3xl pt-[100px] mx-[20px] md:mx-0 xl:mx-[60px] xl:mb-[40px]">
              메카닉
            </h3>
            <ul className="flex flex-col w-full md:flex-row justify-around">
              <Mechanic
                name="조성현"
                img={mechImg1}
                position="Mechanic"
                goal={[
                  "웨스트 앤 피렐리 근무 5년\n",
                  ".",
                  "수입차타이어 탈부착 및 얼라이먼트 전문\n",
                  "수입차량 및 국산차량 경정비 및 하체작업 전문",
                ]}
              />
              <Mechanic
                name="강문헌"
                img={mechImg2}
                position="Mechanic"
                goal={[
                  "레이싱팀 경력 2년\n",
                  ".",
                  "일반 레디얼 타이어및 경기용 슬릭타이어 탈부착전문\n",
                  "하체 셋업전문\n",
                  "경기차량 부품 탈부착 전문",
                ]}
              />
              <Mechanic
                name="한준희"
                img={mechImg3}
                position="Mechanic"
                goal={[
                  "삼성자동차서비스센터 근무 5년5개월",
                  "일반차량 정비 및 차량 메인터넌스 전문",
                ]}
              />
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MechPage;
