import React from 'react';

const Round = ({img, title, track, date, url}) => {
    return (
        <li className="p-[20px] bg-[#1F1F1F] md:m-[20px] xl:m-0 xl:bg-[rgba(0,0,0,0)]">
            <div className="flex flex-col my-center bg-black p-[20px] relative">
                <a href={url} className="absolute w-full h-full cursor-pointer">
                    <div
                        className="absolute top-0 right-0 bg-white hover:text-white hover:bg-red-500 w-[24px] h-[24px] text-black text-xl rounded-full my-center m-[20px] font-thin">
                        <div className="translate-y-[0.5px]">
                            <a href={url}>
                                ﹢
                            </a>
                        </div>
                    </div>
                </a>
                <div className="mb-[20px]">
                    <img className=" md:w-[230px] h-[235px]" src={img} alt=""/>
                </div>
                <div className="flex flex-col text-base w-full">
                    <span className="font-bold text-[20px] mb-[10px]">
                        {title}
                    </span>
                    <span className="pb-[20px] border-b-[1px] mb-[8px]">
                        {track}
                    </span>
                    <span>
                        {date}
                    </span>
                </div>
            </div>
        </li>
    )
};

export default Round;