import React from 'react';
import logo from '../asset/logos/mmxLogoSVG.svg'
import instarLogo from '../asset/icons/instar.png';
// import youtubeLogo from '../asset/icons/youtybeWhite.png';

const Footer = ( { contactScroll, home1Scroll, teamScroll, videoScroll, galleryScroll, scheduleScroll} ) => {
    return (
        <footer className="pt-[120px] md:p-[40px] md:px-[60px] xl:my-center">
            <div className="xl:flex xl:w-[1160px] xl:border-b-[1px] xl:pb-7">
                <div className="my-center flex-col px-[10px] py-[40px] md:items-start relative md:p-0 xl:flex-row xl:my-center xl:justify-between">
                    <div className="mb-[60px] md:mb-30 md:ml-3 xl:flex xl:m-0 xl:mr-[320px]">
                        <img className="w-full h-auto" src={logo} alt=""/>
                    </div>
                    <div className="my-center flex-col">
                        <div className="xl:w-[650px]">
                            <ul className="font-bold text-center md:flex">
                                <li onClick={home1Scroll} className="p-5 hover:text-red-500 cursor-pointer md:text-[14px] md:p-3">
                                    About Us
                                </li>
                                <li onClick={teamScroll} className="p-5 hover:text-red-500 cursor-pointer md:text-[14px] md:p-3">
                                    Team
                                </li>
                                <li onClick={scheduleScroll} className="p-5 hover:text-red-500 cursor-pointer md:text-[14px] md:p-3">
                                    Schedule
                                </li>
                                <li onClick={videoScroll} className="p-5 hover:text-red-500 cursor-pointer md:text-[14px] md:p-3">
                                    Racing Videos
                                </li>
                                <li onClick={galleryScroll} className="p-5 hover:text-red-500 cursor-pointer md:text-[14px] md:p-3">
                                    Gallery
                                </li>
                                <li onClick={contactScroll} className="p-5 hover:text-red-500 cursor-pointer md:text-[14px] md:p-3">
                                    Contact Us
                                </li>
                            </ul>
                            <div className="hidden md:flex border-b-2 w-[648px] xl:hidden"> </div>
                        </div>
                        <div className="flex m-[60px] md:absolute md:top-[-55px] md:right-[-60px] xl:right-[-150px]">
                            <div className="">
                                <a href="https://www.instagram.com/mmxmotorsports_official/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel='noopener noreferrer'>
                                    <img src={instarLogo} alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;