import React , { createContext , useState } from 'react'

export const UserContext = createContext();

function UserStore ( props ) {
    const [imgClicked , setImgClicked] = useState(false);
    
    return (
        <UserContext.Provider value={{
            imgClicked,
            setImgClicked
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserStore