import React from 'react';
import logo from '../../asset/logos/mmxMotersMainLogo.png';

const HomeDetail1 = ({ home1Ref}) => {
    return (
        <section className="w-full flex flex-col my-center relative">
            <video autoPlay muted playsInline loop className="w-full h-screen absolute object-cover top-0">
                <source className="h-screen" src="/homevideo2.mp4" type="video/mp4" data-wf-ignore="true"/>
            </video>
            <div className="my-center flex-col bg-[#000000CC] w-full h-screen z-10 pb-20">
                <div className="my-center w-[155px] md:w-[320px] mb-[32px]">
                    <img className="w-full h-auto" src={logo} alt=""/>
                </div>
                <p className="mx-[20px] leading-6 text-[16px] text-center md:w-[700px] md:text-[20px] leading-[20px] md:leading-[32px] xl:text-[22px] xl:leading-[36px]">
                    2022년 CJ 슈퍼레이스 GT1 클래스에 새롭게 도전하고 있는 신생 팀으로서 능력있는 드라이버 와 메카닉이 한팀이 되서 새로운 역사를 써내려 가보고자 만든 팀으로서
                    21년 동계 시즌 부터 수많은 연습과 테스트를 거친 결과를 올 시즌부터 보여주려 노력 하는 팀입니다
                </p>
            </div>
            <div className="xl:mx-[10px]">
                <div ref={home1Ref} className="mx-[20px] my-[40px] md:flex md:justify-center xl:my-[80px] xl:mx-[60px]">
                    <h2 className="text-[24px] font-bold mb-[20px] md:text-[28px] md:mr-[20px] xl:text-[40px] xl:mr-[128px]">
                        레이싱팀 소개
                    </h2>
                    <p className="text-[14px] leading-6 md:text-base md:w-[486px] md:break-normal xl:text-[18px] xl:w-[737px] xl:leading-[32px] xl:font-medium">
                        레이싱팀이 운영하는 정비소 차량의 트러블 부터 기본적인 케미컬 관리 까지 가능 국산차, 수입차 부분정비부터 국산차 가솔린엔진 오버홀까지 가능 서스펜션 튜닝부터 일반도로 및,
                        와인딩, 스포츠주행까지 원하는 데로 셋업이 가능 브레이크 관련 부품 및 튜닝, 업그레이드 까지 순정 OEM 브레이크부터 고성능 브레이크 시스템 모두 셋팅 가능 렉카 전용 서스펜션
                        개발 및 장착 판매로 인해 타 업체들과는 다른 시스템의 렉카 및 특장차 전용 서스펜션을 셋팅 해 드릴수 있음 여러 자동차 동호회들과 협력 관계를 맺어 자동차를 좋아하고 또한
                        자동차 매니아들의 휴식공간,또는 모임 장소로 활용 가능 본인 차량에 간단한 작업을 직접 해보시고 싶은 분들을 위해 공구 대여 및 지도 교육이 가능
                    </p>
                </div>
            </div>
        </section>
    )
};

export default HomeDetail1;