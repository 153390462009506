import React from "react";

const ContactUs = ({ contactRef }) => {
  return (
    <section ref={contactRef} className="relative my-center">
      <div className="w-full bg-[#1F1F1F] xl:w-[1280px]">
        <div className=" w-full h-auto flex flex-col justify-between md:flex-row md:h-[340px]">
          <div className="p-[40px] px-[20px] z-30 bg-gradient-to-b from-[#1F1F1F] md:p-[40px]">
            <div className="text-[28px] font-bold mb-[14px] md:text-[32px] md:mb-[130px]">
              차량의 트러블 부터
              <br /> 케미컬 관리 까지
            </div>
            <div className="md:text-[14px]">
              MMX Motorsports 전문 정비소
              <br />더 알아보세요!
            </div>
          </div>
          <div className="bg-mEngin bg-center p-[20px] pt-[50px] bg-cover md:flex md:flex-col md:justify-end md:items-end md:w-[420px]">
            <div className="md:p-[40px]">
              <div className="mb-[14px] text-sm">Contact us</div>
              <div className="border-b-[1px] pb-[14px] font-bold text-[28px] w-52 md:text-[32px] md:w-60">
                031) 981-2109
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
